.card{
    border: 0;
    border-radius: $border-radius-small;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: $box-shadow;

    .card-body{
        padding: 15px 15px 10px 15px;

        &.table-full-width{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;

      .card-title{
          margin-top: 10px;
      }
    }

    .map{
        height: 500px;
        border-radius: $border-radius-small;

        &.map-big{
          height: 400px;
        }
    }

    &[data-background-color="orange"]{
        background-color: $primary-color;

        .card-header{
            background-color: $primary-color;
        }

        .card-footer{
            .stats{
                color: $white-color;
            }
        }
    }

    &[data-background-color="red"]{
        background-color: $danger-color;
    }

    &[data-background-color="yellow"]{
        background-color: $warning-color;
    }

    &[data-background-color="blue"]{
        background-color: $info-color;
    }

    &[data-background-color="green"]{
        background-color: $success-color;
    }

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    label{
        font-size: $font-size-small;
        margin-bottom: 5px;
        color: $dark-gray;
    }

    .card-footer{
        background-color: transparent;
        border: 0;


        .stats{
            i{
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
        }

        .btn{
            margin: 0;
        }
    }
}

.card-user{
    .image{
        height: 120px;
    }

    .author{
        text-align: center;
        text-transform: none;
        margin-top: -77px;

        a +  p.description{
            margin-top: -7px;
        }
    }

    .avatar{
        width: 124px;
        height: 124px;
        border: 1px solid $white-color;
        position: relative;
    }

    .card-body{
        min-height: 240px;
    }

    hr{
        margin: 5px 15px;
    }

    .button-container{
        margin-bottom: 6px;
        text-align: center;
    }
}

.card-plain{
    background: transparent;
    box-shadow: none;

    .card-header,
    .card-footer{
        margin-left: 0;
        margin-right: 0;
        background-color: transparent;
    }

    &:not(.card-subcategories).card-body{
        padding-left: 0;
        padding-right: 0;
    }
}

@import "cards/card-chart";
